import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import guinness from '../img/guinness.jpeg'; // Update this with the path to your background image
import teeling from '../img/teeling.jpeg'; // Update this with the path to your background image
import jameson from '../img/jameson.jpg'; // Update this with the path to your background image
import cliffs from '../img/cliffs.jpeg'; // Update this with the path to your background image
import trinity from '../img/trinity.jpg'; // Update this with the path to your background image
import newgrange from '../img/newgrange.jpg'; // Update this with the path to your background image
import titanic from '../img/titanic.jpg';
import w5 from '../img/W5-16.jpg';
import zoo from '../img/zoo.jpeg';
import vtt from '../img/vintageteatrips.jpg';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
const ThingsToDo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const Section = ({ children }) => {
    const { ref, inView } = useInView({
      triggerOnce: false, // Only trigger once when in view
      threshold: 0.05, // Trigger when 10% of the component is in view
    });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
      >
        {children}
      </motion.div>
    );
  };
  const activities = [
    {
      image: guinness, // Replace with actual image path
      title: 'Guinness Storehouse',
      description: 'Dive into the rich history of Ireland\'s most famous beer at the Guinness Storehouse in Dublin. Spread across seven floors, this iconic attraction offers an immersive experience into the brewing process, the story of Arthur Guinness, and the evolution of the brand. Highlights include the Tasting Room, where you can savor the perfect pint, and the Gravity Bar, which offers panoramic views of Dublin\'s skyline. A visit to the Guinness Storehouse is a must for any beer enthusiast or history buff.',
      align: 'left',
    },
    {
      image: teeling, // Replace with actual image path
      title: 'Teeling Distillery',
      description: 'Located in the heart of Dublin, the Teeling Distillery offers a modern twist on Ireland\'s rich whiskey heritage. As the first new distillery to open in Dublin in over 125 years, Teeling provides an authentic and unique experience for whiskey enthusiasts. Visitors can explore the fully operational distillery, witness the traditional process of whiskey-making, and learn about the Teeling family\'s deep-rooted history in the industry. The tour culminates in the tasting room, where guests can sample a range of award-winning whiskeys, including exclusive distillery releases. With its blend of history, craftsmanship, and innovation, a visit to the Teeling Distillery is a must for those looking to experience the spirit of Dublin.',
      align: 'right',
    },
    {
      image: jameson, // Replace with actual image path
      title: 'Jameson Distillery',
      description: 'Discover the legacy of John Jameson and his world-renowned whiskey at the Jameson Distillery Bow St. in Dublin. This historic site offers interactive tours that delve into the history of Jameson whiskey, the art of distillation, and the unique triple-distillation process that gives Jameson its smooth taste. Visitors can participate in whiskey tasting sessions, blending classes, and cocktail-making workshops. The tour concludes with a complimentary drink at JJ\'s Bar, where you can enjoy classic Jameson cocktails or neat pours. A visit to the Jameson Distillery provides a perfect blend of history, education, and enjoyment for whiskey lovers. (If you\'re offered the cask pull for €10 extra take it, it\'s worth it!)',
      align: 'left',
    },
    {
      image: cliffs, // Replace with actual image path
      title: 'Cliffs of Moher',
      description: 'Experience the breathtaking Cliffs of Moher on the west coast of Ireland. These stunning cliffs, rising over 700 feet above the Atlantic Ocean, offer spectacular views and are a must-see for nature lovers. Spanning approximately 14 kilometers, the cliffs provide a variety of scenic trails and viewing points, each offering a unique perspective of this natural wonder. On a clear day, you can see the Aran Islands, Galway Bay, and even the Twelve Bens mountain range in Connemara. The Cliffs of Moher Visitor Experience includes an interactive exhibition about the region\'s geology, history, and wildlife. Don\'t miss the opportunity to visit O\'Brien\'s Tower, a historic observation point built in 1835, which offers the best panoramic views. Whether you\'re a hiker, birdwatcher, or simply looking to soak in the stunning scenery, the Cliffs of Moher provide an unforgettable experience.',
      align: 'right',
    },
    {
      image: trinity, // Replace with actual image path
      title: 'Trinity College and the Book of Kells',
      description: 'Explore the historic and prestigious Trinity College in Dublin, home to one of the most famous manuscripts in the world, the Book of Kells. Founded in 1592, Trinity College boasts beautiful architecture, cobblestone paths, and lush green spaces, creating a serene and inspiring atmosphere. The highlight of any visit is the Old Library, where the Book of Kells is displayed. This illuminated manuscript, created by Celtic monks around 800 AD, is renowned for its intricate artwork and detailed calligraphy. The exhibition provides insights into the making of the manuscript and its significance in Irish history and culture. Don\'t miss the Long Room, a stunning 65-meter-long chamber lined with 200,000 of the library\'s oldest books, and adorned with marble busts of great philosophers and writers. Visiting Trinity College and viewing the Book of Kells offers a profound connection to Ireland\'s scholarly and artistic heritage, making it an essential stop for history buffs and literature enthusiasts alike.',
      align: 'left',
    },
    {
      image: newgrange, // Replace with actual image path
      title: 'Newgrange',
      description: 'Visit Newgrange, a prehistoric monument in County Meath that is older than Stonehenge and the Great Pyramids of Giza. This UNESCO World Heritage site is a large circular mound with an inner stone passageway and chambers. Constructed around 3200 BC, it is best known for the winter solstice sunrise, when sunlight illuminates the inner chamber. The visitor center provides detailed information about the site’s history and significance. Newgrange is an extraordinary example of ancient engineering and a testament to Ireland\'s rich archaeological heritage.',
      align: 'right',
    },
    
    {
      image: titanic, // Replace with actual image path
      title: 'Titanic Belfast',
      description: 'Delve into the fascinating history of the Titanic at Titanic Belfast, the world’s largest Titanic exhibition, on a self-guided tour of the magnificent visitor experience. Explore the institution at your own pace in order to discover insightful exhibits detailing the construction, voyage, and legacy of the infamous ship. Enjoy interactive displays; watch audiovisual presentations; and feel your adrenaline levels surge during an exhilarating Shipyard Ride. Receive complimentary entry to the SS Nomadic with your Titanic Belfast ticket.',
      align: 'left',
    },
    {
      image: w5, // Replace with actual image path
      title: 'W5 Belfast',
      description: 'W5 is an award-winning Science & Discovery Centre in Belfast, Northern Ireland. Part of the Odyssey Complex, W5 is a perfect day out for the whole family.',
      align: 'right',
    },
    {
      image: zoo, // Replace with actual image path
      title: 'Dublin Zoo',
      description: 'One of the world\'s oldest zoos, Dublin Zoo is also modern in design and houses more than 600 animals. It has been redesigned in recent years to be a safe environment for people and the animals with gardens, lakes and natural habitats.',
      align: 'left',
    },
    {
      image: vtt, // Replace with actual image path
      title: 'Vintage Tea Trips',
      description: 'Combine a sightseeing tour of Dublin with afternoon tea served on a vintage double-decker bus. The itinerary includes famous landmarks such as the Guinness Brewery, St. Patrick’s Cathedral, Christchurch, Wood Quay, O’Connell Street, Trinity College, Georgian Dublin, and others. See the sights while enjoying a cup of seasonal tea or coffee along with some delicacies. Use the code "womaninwool" to get 10% off!',
      align: 'right',
    },
  ];

  return (
    <Box>
      <Typography 
        variant="h2" 
        sx={{ 
          textAlign: 'center', 
          margin: '40px 0', 
          fontWeight: 'bold', 
          textTransform: 'uppercase', 
          letterSpacing: '2px' 
        }}
         id="things-to-do"
      >
        Things to Do
      </Typography>
      {activities.map((activity, index) => (
        <Section>
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : (activity.align === 'left' ? 'row' : 'row-reverse'),
            alignItems: 'center',
            textAlign: isMobile ? 'center' : (activity.align === 'left' ? 'left' : 'right'),
            margin: '20px 0',
            padding: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '20px',
          }}
        >
          <Box
            component="img"
            loading="lazy"  // This enables lazy loading

            src={activity.image}
            sx={{
              width: isMobile ? '100%' : '50%',
              height: isMobile ? '100%' : '100%',
              objectFit: 'cover',
              borderRadius: '20px',
            }}
          />
          <Box
            sx={{
              width: isMobile ? '100%' : '50%',
              padding: isMobile ? '10px' : '20px',
            }}
          >
            <Typography variant="h4" gutterBottom>{activity.title}</Typography>
            <Typography variant="subtitle1">{activity.description}</Typography>
          </Box>
        </Box>
        </Section>
      ))}
    </Box>
  );
};

export default ThingsToDo;
