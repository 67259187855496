import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const Transportation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        padding: isMobile ? '0' : '50px',
        borderRadius: '20px',
        paddingTop: '20px',
        width: '95%',
        textAlign: isMobile ? 'center' : 'left',
        marginBottom: '40px', // Added margin-bottom for spacing
      }}
    >
      <Box
        sx={{
          width: isMobile ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: isMobile ? '20px' : '0', // Margin for spacing on mobile
        }}
      >
        <Box
          sx={{
            padding: isMobile ? '20px' : '50px',
            borderRadius: '80px',
            maxWidth: '400px',
            width: '80%',
            height: 'auto',
            margin: isMobile ? '20px' : '0',
          }}
        >
          <Typography id="transportation" variant="h3" sx={{ textAlign: 'center' }} gutterBottom>
            Transportation
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: 'center' }} gutterBottom>
            While we would love to spend the wedding weekend together at Tankardstown, we only have our venue for one night!
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: 'center' }} gutterBottom>
            <br></br>If you're still looking to spend the night with us at Tankardstown, follow the instructions below for booking online. There is ample parking if you'd like to drive or we will be putting on a bus that will take people from Dublin City Centre to the venue, and back later that night. If you'd like to be included in bus, there's a section in your RSVP to fill out.
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: 'center' }} gutterBottom>
            <br></br>As for hotels in Dublin for the rest of your stay, here are a few we recommend:
            <br></br><br></br><a href="https://www.thealexhotel.ie/">The Alex Hotel</a>
            <br></br><a href="https://www.claytonhotels.com/cardiff/?gclid=88ee142672cb14ee386b72e5d1138a88&gclsrc=3p.ds&msclkid=88ee142672cb14ee386b72e5d1138a88">The Clayton Hotel Cardiff Lane</a>
            <br></br><a href="https://thedean.ie/dublin/">The Dean Hotel</a>
            <br></br><a href="https://www.hilton.com/en/locations/ireland/dublin/">The Hilton Dublin</a>
            <br></br><a href="https://themayson.ie/?msclkid=2cb21e9a81861776a18b785925cf5d26">The Mayson Hotel</a>
            <br></br><a href="https://www.lockeliving.com/en/dublin/beckett-locke">Beckett Locke, North Docks</a>
            <br></br><a href="https://www.lockeliving.com/en/dublin/zanzibar-locke">Zanzibar Locke, Ha'Penny Bridge</a>
          </Typography>
          <Typography id="transportation" variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
            <br></br>Venue Booking - Tankardstown
          </Typography>
          <Typography variant="subtitle2" sx={{ textAlign: 'center' }} gutterBottom>
            Visit <a href="https://www.tankardstown.ie/">https://www.tankardstown.ie/</a><br></br>
            Choose "Book now"<br></br>
            Choose "Check availability"<br></br>
            Enter date "29/12/2024 to 30/12/2024"<br></br>
            Under the date, where it says 'insert code', you enter <strong>M&CW1224</strong> in the box below
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: isMobile ? '100%' : '50%',
          height: 'auto', // Allow height to auto adjust
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=Tankardstown+House,+Rathkenny,+Co.+Meath,+Ireland&key=AIzaSyAwwWfozC-wQ77bxorfa1yWzdgxtvSRdaQ"
          width="100%"
          height={isMobile ? '300px' : '100%'} // Set height based on mobile or not
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Transportation;
