import React, { useState, useEffect } from 'react';
import { getGuests, addGuest, updateGuest, deleteGuest, getParties, addParty, updateParty } from '../helpers/firestore';
import { TextField, Button, Container, Typography, List, ListItem, ListItemText, IconButton, MenuItem, Select, FormControl, InputLabel, Collapse } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdminPanel = () => {
  const [guests, setGuests] = useState([]);
  const [parties, setParties] = useState([]);
  const [newGuest, setNewGuest] = useState('');
  const [newPartyName, setNewPartyName] = useState('');
  const [selectedPartyForNewGuest, setSelectedPartyForNewGuest] = useState('');
  const [selectedGuest, setSelectedGuest] = useState('');
  const [selectedPartyForExistingGuest, setSelectedPartyForExistingGuest] = useState('');
  const [guestSearchTerm, setGuestSearchTerm] = useState('');
  const [partySearchTerm, setPartySearchTerm] = useState('');
  const [expandedParties, setExpandedParties] = useState({});

  useEffect(() => {
    fetchGuests();
    fetchParties();
  }, []);

  const fetchGuests = async () => {
    const guestsData = await getGuests();
    // Sort guests alphabetically by name
    const sortedGuests = guestsData.sort((a, b) => a.name.localeCompare(b.name));
    setGuests(sortedGuests);
  };

  const fetchParties = async () => {
    const partiesData = await getParties();
    // Sort parties alphabetically by name
    const sortedParties = partiesData.sort((a, b) => a.name.localeCompare(b.name));
    setParties(sortedParties);
  };

  const handleAddParty = async () => {
    const trimmedPartyName = newPartyName.trim();

    if (!trimmedPartyName) {
      alert('Please enter a party name');
      return;
    }

    const newPartyData = { name: trimmedPartyName, guests: [] };
    await addParty(newPartyData);

    setNewPartyName('');
    fetchParties();
  };

  const handleRemoveGuest = async (id) => {
    await deleteGuest(id);
    fetchGuests();
    fetchParties();
  };

  const handleAddGuest = async () => {
    const trimmedGuestName = newGuest.trim();

    if (!trimmedGuestName) {
      alert('Please enter the guest name');
      return;
    }

    const newGuestData = {
      name: trimmedGuestName,
      name_lowercase: trimmedGuestName.toLowerCase(),
      partyId: selectedPartyForNewGuest || null, // Optional party assignment
      rsvp: null,
      transportation: false,
      dietary: [],
      otherDietary: ''
    };

    const guestId = await addGuest(newGuestData);

    if (!guestId) {
      console.error("Failed to obtain guest ID after adding guest");
      return;
    }

    if (selectedPartyForNewGuest) {
      const partyData = parties.find(party => party.id === selectedPartyForNewGuest);

      if (!partyData) {
        console.error("Selected party not found");
        return;
      }

      const updatedPartyData = {
        ...partyData,
        guests: [...(partyData.guests || []), guestId]
      };

      await updateParty(selectedPartyForNewGuest, updatedPartyData);
    }

    setNewGuest('');
    setSelectedPartyForNewGuest('');
    fetchGuests();
    fetchParties();
  };

  const handleAssignGuestToParty = async () => {
    if (!selectedGuest || !selectedPartyForExistingGuest) {
      alert('Please select a guest and a party');
      return;
    }

    const guestData = guests.find(guest => guest.id === selectedGuest);
    const updatedGuestData = { ...guestData, partyId: selectedPartyForExistingGuest };
    await updateGuest(selectedGuest, updatedGuestData);

    const partyData = parties.find(party => party.id === selectedPartyForExistingGuest);
    const updatedPartyData = { ...partyData, guests: [...partyData.guests, selectedGuest] };
    await updateParty(selectedPartyForExistingGuest, updatedPartyData);

    setSelectedGuest('');
    setSelectedPartyForExistingGuest('');
    fetchGuests();
    fetchParties();
  };

  const handleToggleParty = (partyId) => {
    setExpandedParties(prevState => ({
      ...prevState,
      [partyId]: !prevState[partyId]
    }));
  };

  const filteredGuests = guests.filter(guest => guest.name.toLowerCase().includes(guestSearchTerm.toLowerCase()));
  const filteredParties = parties.filter(party => party.name.toLowerCase().includes(partySearchTerm.toLowerCase()));

  // Filter out guests that are already assigned to a party
  const unassignedGuests = guests.filter(guest => !guest.partyId);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Panel
      </Typography>

      {/* Form to Add New Party */}
      <Typography variant="h5" component="h2" gutterBottom>
        Add New Party
      </Typography>
      <TextField
        fullWidth
        type="text"
        value={newPartyName}
        onChange={(e) => setNewPartyName(e.target.value)}
        placeholder="Party name"
        margin="normal"
        variant="outlined"
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={handleAddParty}
      >
        Add Party
      </Button>

      {/* Form to Add New Guest */}
      <Typography variant="h5" component="h2" gutterBottom>
        Add New Guest
      </Typography>
      <TextField
        fullWidth
        type="text"
        value={newGuest}
        onChange={(e) => setNewGuest(e.target.value)}
        placeholder="Guest name"
        margin="normal"
        variant="outlined"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Assign to Party (Optional)</InputLabel>
        <Select
          value={selectedPartyForNewGuest}
          onChange={(e) => setSelectedPartyForNewGuest(e.target.value)}
        >
          {parties.map(party => (
            <MenuItem key={party.id} value={party.id}>
              {party.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={handleAddGuest}
      >
        Add Guest
      </Button>

      {/* Form to Assign Existing Guest to Party */}
      <Typography variant="h5" component="h2" gutterBottom>
        Assign Existing Guest to Party
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Guest</InputLabel>
        <Select
          value={selectedGuest}
          onChange={(e) => setSelectedGuest(e.target.value)}
        >
          {unassignedGuests.map(guest => (
            <MenuItem key={guest.id} value={guest.id}>
              {guest.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Select Party</InputLabel>
        <Select
          value={selectedPartyForExistingGuest}
          onChange={(e) => setSelectedPartyForExistingGuest(e.target.value)}
        >
          {parties.map(party => (
            <MenuItem key={party.id} value={party.id}>
              {party.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={handleAssignGuestToParty}
      >
        Assign Guest to Party
      </Button>

      {/* Guest List with Search */}
      <Typography variant="h5" component="h2" gutterBottom>
        Guest List
      </Typography>
      <TextField
        fullWidth
        type="text"
        value={guestSearchTerm}
        onChange={(e) => setGuestSearchTerm(e.target.value)}
        placeholder="Search Guests"
        margin="normal"
        variant="outlined"
      />
      <List>
        {filteredGuests.map(guest => (
          <ListItem key={guest.id} secondaryAction={
            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveGuest(guest.id)}>
              <DeleteIcon />
            </IconButton>
          }>
            <ListItemText
              primary={guest.name}
              secondary={
                <div>
                  <div>
                    {guest.rsvp === 'yes' ? (
                      <CheckCircleIcon style={{ color: 'green' }} />
                    ) : guest.rsvp === 'no' ? (
                      <CancelIcon style={{ color: 'red' }} />
                    ) : (
                      'No response'
                    )}
                  </div>
                  <div>Transportation: {guest.transportation ? 'Yes' : 'No'}</div>
                  <div>Dietary: {guest.dietary.join(', ')} {guest.dietary.includes('Other') && `(${guest.otherDietary})`}</div>
                </div>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Party List with Search and Collapsible Sections */}
      <Typography variant="h5" component="h2" gutterBottom>
        Party List
      </Typography>
      <TextField
        fullWidth
        type="text"
        value={partySearchTerm}
        onChange={(e) => setPartySearchTerm(e.target.value)}
        placeholder="Search Parties"
        margin="normal"
        variant="outlined"
      />
      <List>
        {filteredParties.map(party => (
          <div key={party.id}>
            <ListItem button onClick={() => handleToggleParty(party.id)}>
              <ListItemText primary={party.name} />
              {expandedParties[party.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={expandedParties[party.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {party.guests.map(guestId => {
                  const guest = guests.find(guest => guest.id === guestId);
                  return guest ? (
                    <ListItem key={guest.id} disableGutters>
                      <ListItemText
                        primary={guest.name}
                        secondary={
                          <div>
                            <div>
                              {guest.rsvp === 'yes' ? (
                                <CheckCircleIcon style={{ color: 'green' }} />
                              ) : guest.rsvp === 'no' ? (
                                <CancelIcon style={{ color: 'red' }} />
                              ) : (
                                'No response'
                              )}
                            </div>
                            <div>Transportation: {guest.transportation ? 'Yes' : 'No'}</div>
                            <div>Dietary: {guest.dietary.join(', ')} {guest.dietary.includes('Other') && `(${guest.otherDietary})`}</div>
                          </div>
                        }
                      />
                    </ListItem>
                  ) : null;
                })}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </Container>
  );
};

// New Component to Display Updated RSVPs
const UpdatedRSVPs = () => {
  const [guests, setGuests] = useState([]);

  useEffect(() => {
    fetchGuests();
  }, []);

  const fetchGuests = async () => {
    const guestsData = await getGuests();
    // Sort guests alphabetically by name
    const sortedGuests = guestsData.sort((a, b) => a.name.localeCompare(b.name));
    setGuests(sortedGuests);
  };

  

  const rsvpYes = guests.filter(guest => guest.rsvp === 'yes');
  const rsvpNo = guests.filter(guest => guest.rsvp === 'no');

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Updated RSVPs
      </Typography>
      
      <Typography variant="h5" component="h2" gutterBottom>
        RSVP Yes ({rsvpYes.length})
      </Typography>
      <List>
        {rsvpYes.map(guest => (
          <ListItem key={guest.id}>
            <ListItemText primary={guest.name} />
          </ListItem>
        ))}
      </List>

      <Typography variant="h5" component="h2" gutterBottom>
        RSVP No ({rsvpNo.length})
      </Typography>
      <List>
        {rsvpNo.map(guest => (
          <ListItem key={guest.id}>
            <ListItemText primary={guest.name} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default AdminPanel;
export { UpdatedRSVPs };
