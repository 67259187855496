import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import backgroundImage from '../img/tankardstown2.jpeg'; // Update this with the path to your background image
import blackTieInfo from '../img/BlackTieInfo.png'; // Update this with the path to your background image

const DressCode = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: isMobile ? '0' : '50px',
          borderRadius: '20px',
          width: '100%',
          textAlign: isMobile ? 'center' : 'left',
          marginBottom: '40px', // Added margin-bottom to ensure spacing between sections
        }}
      >
        <Box
          component="img"
          src={backgroundImage}
          sx={{
            width: isMobile ? '95%' : '50%',
            paddingTop: isMobile ? '5px' : '',
            height: 'auto', // Allow the image height to auto-adjust
            objectFit: 'cover',
            objectPosition: isMobile ? 'left' : 'center left',
            borderRadius: '20px',
            marginBottom: isMobile ? '20px' : '0', // Add margin-bottom on mobile
          }}
        />
        <Box
          sx={{
            width: isMobile ? '100%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '80px',
              maxWidth: '400px',
              width: '80%',
              margin: isMobile ? '20px' : '0',
              padding: isMobile ? '20px' : '50px',
            }}
          >
            <Typography id="dress-code" variant="h3" sx={{ textAlign: 'center' }} gutterBottom>
              <br />The dress code is Black Tie Optional.
            </Typography>
            <Typography id="dress-code" variant="h3" sx={{ textAlign: 'center' }} gutterBottom>
              <br />For the guys:
            </Typography>
            <Typography variant="subtitle2" sx={{ textAlign: 'center', fontStyle: 'italic' }} gutterBottom>
              Tuxedo or Dark suit with Bow Tie or Neck Tie.
              <br />
              Black or Brown dress shoes
              <br />
              <br />
              For some inspiration checkout this site:
              <br />
              <a href="https://www.theknot.com/content/what-to-wear-formal-black-tie-optional-black-tie-invited?srsltid=AfmBOoo1-XMAvpBmCaSJZHcC8KyuCo1LC_eaq1leBF18MiyU37BRJFZS#black-tie-optional-tuxedos-and">Black Tie Optional for Guys</a>
            </Typography>
            <Typography variant="h3" sx={{ textAlign: 'center' }} gutterBottom>
              For the ladies:
            </Typography>
            <Typography variant="subtitle2" sx={{ textAlign: 'center', fontStyle: 'italic' }} gutterBottom>
              Full length gown or cocktail dress
              <br />
              <br />
              For some inspiration check out this link:
              <br />
              <a href="https://www.brides.com/black-tie-optional-wedding-attire-4800698#toc-black-tie-optional-wedding-attire-for-women">Black Tie Optional for Ladies</a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DressCode;
