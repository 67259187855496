import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAjpsAtavSpGP41hhXVlZjfFN71BKOe3pk",
  authDomain: "carlandmaciawedding.firebaseapp.com",
  projectId: "carlandmaciawedding",
  storageBucket: "carlandmaciawedding.appspot.com",
  messagingSenderId: "974008262768",
  appId: "1:974008262768:web:a25a28197aaa4158cb54b5",
  measurementId: "G-3NTQTMVG47"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, analytics };
