import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import fade_street from '../img/fade_street.jpeg'; // Update this with the path to your background image
import arisu from '../img/arisu.jpg'; // Update this with the path to your background image
import bar1661 from '../img/1661.jpeg'; // Update this with the path to your background image
import palacebar from '../img/palacebardublin.jpg'; // Update this with the path to your background image
import brazenhead from '../img/The-Brazen-Head-Outside.jpg'; // Update this with the path to your background image
import sole from '../img/sole.jpeg'; // Update this with the path to your background image
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
const PubsandGrub = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const Section = ({ children }) => {
    const { ref, inView } = useInView({
      triggerOnce: true, // Only trigger once when in view
      threshold: 0.1, // Trigger when 10% of the component is in view
    });
  
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
        transition={{ duration: 1.2, ease: 'easeOut' }}
      >
        {children}
      </motion.div>
    );
  };
  const activities = [
    {
      image: fade_street, // Replace with actual image path
      title: 'Fade Street Social',
      description: 'One of our favourite places to show people that Irish food isn\'t boring! The woodfired restaurant is casual and delicious, or the main restaurant for a more refined and deeper dive into Irish cuisine.',
      align: 'left',
    },
    {
      image: arisu, // Replace with actual image path
      title: 'Arisu Korean BBQ',
      description: 'Arisu on Capel Street is Macia\'s comfort food and generally a pre cursor to the next on the list! Straight forward ordering, meal for two and let them cook it at the table.',
      align: 'right',
    },
    {
      image: bar1661, // Replace with actual image path
      title: 'Bar 1661',
      description: 'A poitin cocktail focused bar, that makes amazing drinks. Order a Belfast Coffee and a Golden Moment, you won\'t be disappointed.',
      align: 'left',
    },
    {
      image: palacebar, // Replace with actual image path
      title: 'Palace Bar',
      description: 'Likely place to find Carl after a whiskey event, or any other excuse for that matter. Perfect place for a guinness or a whiskey, if it\'s open, try go upstairs to the whiskey palace, they still serve Guinness up there too, but it\'s a great look at loads of historic Irish Whiskey bottles and Bar paraphernalia',
      align: 'right',
    },
    {
      image: brazenhead, // Replace with actual image path
      title: 'Brazen Head',
      description: 'Dubbed the oldest pub in Ireland, it\'s got a great atmosphere and usually has traditional irish music every night!',
      align: 'left',
    },
    {
      image: sole, // Replace with actual image path
      title: 'Sole Seafood and Grill',
      description: 'An Island should have some good seafood, SOLE deliver on that with a lovely restaurant to enjoy it in.',
      align: 'right',
    },
  ];

  return (
    <Box>
      <Typography 
        variant="h2" 
        sx={{ 
          textAlign: 'center', 
          margin: '40px 0', 
          fontWeight: 'bold', 
          textTransform: 'uppercase', 
          letterSpacing: '2px' 
        }}
         id="pubs-and-grub"
      >
        Pubs and Grub
      </Typography>
      {activities.map((activity, index) => (
        <Section>
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : (activity.align === 'left' ? 'row' : 'row-reverse'),
            alignItems: 'center',
            textAlign: isMobile ? 'center' : (activity.align === 'left' ? 'left' : 'right'),
            margin: '20px 0',
            padding: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '20px',
          }}
        >
          <Box
            component="img"
            loading="lazy"  // This enables lazy loading

            src={activity.image}
            sx={{
              width: isMobile ? '100%' : '50%',
              height: isMobile ? '100%' : '100%',
              objectFit: 'cover',
              borderRadius: '20px',
            }}
          />
          <Box
            sx={{
              width: isMobile ? '100%' : '50%',
              padding: isMobile ? '10px' : '20px',
            }}
          >
            <Typography variant="h4" gutterBottom>{activity.title}</Typography>
            <Typography variant="subtitle1">{activity.description}</Typography>
          </Box>
        </Box>
        </Section>
      ))}
    </Box>
  );
};

export default PubsandGrub;
