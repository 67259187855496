import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { TextField, Button, Typography } from '@mui/material';
import backgroundImage from '../img/45.jpg'; // Update this with the path to your background image

const Login = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const correctPassword = "carlandmacia2024"; // Set your password here

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      sessionStorage.setItem('sessionToken', correctPassword);
      sessionStorage.setItem('authenticated', true);
      navigate('/');
    } else {
      alert('Incorrect Password');
    }
  };

  return (
    <div style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      <div style={{
        background: 'rgba(255, 255, 255, 0.8)', 
        padding: '20px', 
        borderRadius: '8px',
        maxWidth: '400px',
        width: '100%'
      }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            margin="normal"
            variant="outlined"
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginTop: '20px' }}
          >
            Login
          </Button>
        </form>
        <Link to="/admin-login" style={{ textDecoration: 'none' }}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            size="large"
            style={{ marginTop: '20px' }}
          >
            Admin Login
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Login;
