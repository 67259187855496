import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Typography, Container } from '@mui/material';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import AdminLogin from './pages/AdminLogin';
import AdminPanel from './pages/AdminPanel';
import {UpdatedRSVPs} from './pages/AdminPanel';
import Homepage from './pages/Homepage';
import RSVP from './pages/RSVP';

function App() {
  return (
    <Container disableGutters maxWidth="100vw" sx={{margin:0,padding:0,width: "100%",height:"auto",overflow: 'hidden' }} >
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/" element={<ProtectedRoute component={Homepage} />} />
          <Route path="/admin" element={<ProtectedRoute component={AdminPanel} adminOnly />} />
          <Route path="/admin/updated-rsvps" element={<ProtectedRoute component={UpdatedRSVPs} adminOnly />} />

          <Route path="/rsvp" element={<ProtectedRoute component={RSVP} />} />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
