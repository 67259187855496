import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { auth } from '../firebase';

// Function to check session token or Firebase Auth
const checkAuth = () => {
  const sessionToken = sessionStorage.getItem('sessionToken');
  const admin = sessionStorage.getItem('adminAuthenticated');
  const user = auth.currentUser;
  return sessionToken === 'carlandmacia2024' || user != null || admin == 'true' ;
};

// Function to get guests
const getGuests = async () => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return [];
  }

  const guestCollection = collection(db, 'guests');
  const guestSnapshot = await getDocs(guestCollection);
  return guestSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Function to get parties
const getParties = async () => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return [];
  }

  const partyCollection = collection(db, 'parties');
  const partySnapshot = await getDocs(partyCollection);
  return partySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Function to add a guest
// Function to add a guest and return the document ID
const addGuest = async (guest) => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return null;
  }

  try {
    const docRef = await addDoc(collection(db, 'guests'), guest);
    return docRef.id; // Return the document ID
  } catch (error) {
    console.error('Error adding guest:', error);
    return null;
  }
};

// Function to add a party
const addParty = async (party) => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return;
  }

  await addDoc(collection(db, 'parties'), party);
};

// Function to update a guest
const updateGuest = async (guestId, guestData) => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return;
  }

  const guestDoc = doc(db, 'guests', guestId);
  await updateDoc(guestDoc, guestData);
};

// Function to update a party
const updateParty = async (partyId, partyData) => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return;
  }

  const partyDoc = doc(db, 'parties', partyId);
  await updateDoc(partyDoc, partyData);
};

// Function to delete a guest
const deleteGuest = async (guestId) => {
  if (!checkAuth()) {
    alert('Unauthorized access');
    return;
  }

  const guestDoc = doc(db, 'guests', guestId);
  await deleteDoc(guestDoc);
};

export { getGuests, addGuest, updateGuest, deleteGuest, getParties, addParty, updateParty };
