import React, { useState, useEffect } from 'react';
import { Typography, useMediaQuery, useTheme, Box, Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EventIcon from '@mui/icons-material/Event';
import { Link } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import Layout from '../components/Layout';
import Details from './Details';
import DressCode from './DressCode';
import Transportation from './Transportation';
import ThingsToDo from './ThingsToDo';
import PubsandGrub from './PubandGrub';
import bg from '../img/bg.png'; // Update this with the path to your background image
import backgroundImage from '../img/67.jpg'; // Update this with the path to your background image

const Section = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.05,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 100 }}  // Reduce the initial y value for better performance
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 0.4, ease: 'easeOut' }} // Reduce the duration to make the animation snappier
      style={{ marginBottom: '40px' }}
    >
      {children}
    </motion.div>
  );
};
const Homepage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showButton, setShowButton] = useState(false);

  // Handle scroll to top
  const handleScrollToTop = () => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the absolute top
  };

  // Show or hide the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center',
        padding: 0,
        margin: 0,
        maxWidth:'100vw'
      }}
    >
      <Layout />
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: isMobile ? '0' : '50px',
            width: '100%',
            textAlign: isMobile ? 'center' : 'left',
            marginBottom: '20px', // Added margin bottom to separate sections
          }}
        >
          <Box
            component="img"
            src={backgroundImage}
            loading="lazy"  // This enables lazy loading

            sx={{
              width: isMobile ? '100%' : '50%',
              height: 'auto',
              objectFit: 'cover',
              objectPosition: isMobile ? 'left' : 'center left',
              borderRadius: '20px',
              marginBottom: isMobile ? '20px' : 0, // Add margin to image on mobile
            }}
          />
          <Box
            sx={{
              width: isMobile ? '100%' : '50%',
              padding: isMobile ? '10px' : '20px',
              textAlign: 'center',
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 250 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 2.5, ease: 'easeOut' }}
            >
              <Typography
                variant="h2"
                component="h1"
                sx={{ fontFamily: 'Oranienbaum' }}
                gutterBottom
              >
                Cheers, Sláinte, L'chaim <br />
                We're getting married!
              </Typography>
            </motion.div>
          </Box>
        </Box>

      <Section>
        <Details />
      </Section>
      <Section>
        <DressCode />
      </Section>
      <Section>
        <Transportation />
      </Section>
      <ThingsToDo />
      <PubsandGrub />
      {/* Floating RSVP Button */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}
      >
        <Fab
          color="secondary"
          aria-label="RSVP"
          component={Link}
          to="/rsvp"
          sx={{
            backgroundColor: '#4caf50',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#388e3c',
            },
          }}
        >
          RSVP
        </Fab>
      </motion.div>
      {/* Back to Top Button */}
      {showButton && (
        <div
          style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 1000 }}
        >
          <Fab color="primary" aria-label="scroll back to top" onClick={handleScrollToTop}>
            <ArrowUpwardIcon />
          </Fab>
        </div>
      )}

      
    </Box>
  );
};

export default Homepage;
