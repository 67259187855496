import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, adminOnly, ...rest }) => {
  const isAuthenticated = sessionStorage.getItem('authenticated') === 'true';
  const isAdminAuthenticated = sessionStorage.getItem('adminAuthenticated') === 'true';

  if (adminOnly) {
    return isAdminAuthenticated ? <Component {...rest} /> : <Navigate to="/admin-login" />;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
