import React from 'react';
import { Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import backgroundImage from '../img/tankardstown.jpeg'; // Update this with the path to your background image

const Details = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        alignItems: isMobile ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        padding: isMobile ? '0' : '50px',
        borderRadius: '20px',
        paddingTop: '20px',
        width: '100%',
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      {/* Text Box - On the left for desktop, below the image on mobile */}
      <Box
        sx={{
          width: isMobile ? '90%' : '50%',
          padding: isMobile ? '10px' : '20px',
          textAlign: 'center',
        }}
      >
        <Typography
          id="details"
          variant="h3"
          sx={{ textAlign: 'start',fontFamily:"Oranienbaum" }}
          gutterBottom
        >
          10 YEARS IS <br />
          THE
          <br />
          PERFECT
          <br />
          TIME TO TIE
          <br />
          THE KNOT
        </Typography>
        <Typography
          variant="h4"
          sx={{ textAlign: 'start', letterSpacing: -2, fontWeight: 500,fontFamily:"Oranienbaum" }}
          gutterBottom
        >
          __________
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: 'start', fontStyle: 'italic',fontFamily:"Playfair Display" }}
          gutterBottom
        >
          Along with their families you are joyfully invited to
          <br />
          join us to celebrate the marriage of
        </Typography>
        <Typography
          variant="h3"
          sx={{ textAlign: 'start',fontFamily:"Oranienbaum" }}
          gutterBottom
        >
          MACIA &
          <br />
          CARL
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ textAlign: 'start', fontStyle: 'italic',fontFamily:"Playfair Display" }}
          gutterBottom
        >
          Sunday the twenty-ninth of December
          <br />
          Three o'clock in the afternoon
          <br />
          Tankardstown House
          <br />
          Rathkenny, Co.Meath, C15 D535
        </Typography>
      </Box>
      
      {/* Image Box - On the right for desktop, on top for mobile */}
      <Box
        component="img"
        src={backgroundImage}
        sx={{
          width: isMobile ? '95%' : '50%',
          marginBottom: isMobile ? '20px' : '0', // Adds space between image and text on mobile
          height: isMobile ? 'auto' : '80%',
          objectFit: 'cover',
          objectPosition: isMobile ? 'center': 'center',

          borderRadius: '20px',
        }}
      />
    </Box>
  );
};

export default Details;
