import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, useTheme, useMediaQuery, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

import logo from '../img/WeddingBands.png'; // Update this with the path to your logo

const handleScrollToButton = (text) => {
  const formattedText = text.replace(/\s+/g, '-').toLowerCase();
  document.getElementById(formattedText).scrollIntoView({ behavior: 'smooth' });
};

const NavButton = styled(Button)({
  color: 'white',
  fontWeight: 'normal',
  fontSize: '1rem',
  fontFamily:"Oranienbaum",
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: 'grey',
  },
});
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(green[700]),
  backgroundColor: green[700],
  '&:hover': {
    backgroundColor: green[900],
  },
}));

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250,height:'100%',fontFamily:"Oranienbaum", backgroundColor:'black' }}>
      <List>
        {['Details', 'Dress Code', 'Transportation', 'Things to Do', 'Pubs and Grub'].map((text) => (
          <ListItem button key={text}>
            <NavButton onClick={() => handleScrollToButton(text)}>
              <ListItemText sx={{fontFamily:"Oranienbaum"}}  primary={text} />
            </NavButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Container disableGutters maxWidth="100vw" sx={{ margin: 0, padding: 0, width: "100%", height:'75px', backgroundColor: "#000" }}>
      <AppBar position="static" color="primary" sx={{ zIndex: 1000, padding: '10px 20px', backgroundColor: "#000" }}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: isMobile ? 'block' : 'none' }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex',fontFamily:"Oranienbaum", justifyContent: 'space-around', alignItems: 'center' }}>
            {!isMobile && ['Details', 'Dress Code', 'Transportation', 'Things to Do', 'Pubs and Grub'].map((text) => (
              <NavButton sx={{fontFamily:"Oranienbaum"}}  key={text} onClick={() => handleScrollToButton(text)}>
                {text}
              </NavButton>
            ))}
          </Box>
          <ColorButton variant="contained" component={Link} to="/rsvp">RSVP</ColorButton>
        </Toolbar>
      </AppBar>
      <Toolbar /> {/* This is to offset the height of the AppBar */}
      <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </Container>
  );
};

export default Layout;
