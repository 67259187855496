import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, writeBatch, doc } from 'firebase/firestore';
import { TextField, Button, Container, Typography, List, ListItem, ListItemText, Checkbox, RadioGroup, Radio, FormControlLabel, FormControl, FormGroup, FormLabel, Box } from '@mui/material';
import bg from '../img/bg.png'; // Update this with the path to your background image

const RSVP = () => {
  const [name, setName] = useState('');
  const [guest, setGuest] = useState(null);
  const [partyGuests, setPartyGuests] = useState([]);
  const [responses, setResponses] = useState({});
  const [transportation, setTransportation] = useState({});
  const [dietary, setDietary] = useState({});
  const [otherDietary, setOtherDietary] = useState({});

  const handleSearch = async () => {
    const lowerCaseName = name.toLowerCase().trim();
    const q = query(collection(db, 'guests'), where('name_lowercase', '==', lowerCaseName));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const guestDoc = snapshot.docs[0];
      setGuest(guestDoc);
      if (guestDoc.data().partyId) {
        const partySnapshot = await getDocs(query(collection(db, 'guests'), where('partyId', '==', guestDoc.data().partyId)));
        setPartyGuests(partySnapshot.docs);
        setResponses(partySnapshot.docs.reduce((acc, guest) => ({ ...acc, [guest.id]: guest.data().rsvp || 'no' }), {}));
        setTransportation(partySnapshot.docs.reduce((acc, guest) => ({ ...acc, [guest.id]: guest.data().transportation || false }), {}));
        setDietary(partySnapshot.docs.reduce((acc, guest) => ({ ...acc, [guest.id]: guest.data().dietary || [] }), {}));
        setOtherDietary(partySnapshot.docs.reduce((acc, guest) => ({ ...acc, [guest.id]: guest.data().otherDietary || '' }), {}));
      } else {
        setPartyGuests([guestDoc]);
        setResponses({ [guestDoc.id]: guestDoc.data().rsvp || 'no' });
        setTransportation({ [guestDoc.id]: guestDoc.data().transportation || false });
        setDietary({ [guestDoc.id]: guestDoc.data().dietary || [] });
        setOtherDietary({ [guestDoc.id]: guestDoc.data().otherDietary || '' });
      }
    } else {
      alert('Guest not found');
    }
  };

  const handleRSVP = async () => {
    const batch = writeBatch(db);
    for (const guestId of Object.keys(responses)) {
      const guestDoc = doc(db, 'guests', guestId);
      batch.update(guestDoc, {
        rsvp: responses[guestId],
        transportation: transportation[guestId],
        dietary: dietary[guestId],
        otherDietary: dietary[guestId].includes('Other') ? otherDietary[guestId] : ''
      });
    }
    await batch.commit();
    alert('RSVP updated');
  };

  const handleResponseChange = (guestId, response) => {
    setResponses(prevResponses => ({ ...prevResponses, [guestId]: response }));
  };

  const handleTransportationChange = (guestId, value) => {
    setTransportation(prevTransportation => ({ ...prevTransportation, [guestId]: value }));
  };

  const handleDietaryChange = (guestId, dietaryOption) => {
    setDietary(prevDietary => {
      const newDietary = prevDietary[guestId]?.includes(dietaryOption)
        ? prevDietary[guestId].filter(option => option !== dietaryOption)
        : [...(prevDietary[guestId] || []), dietaryOption];
      return { ...prevDietary, [guestId]: newDietary };
    });
  };

  const handleOtherDietaryChange = (guestId, value) => {
    setOtherDietary(prevOtherDietary => ({ ...prevOtherDietary, [guestId]: value }));
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'auto',
        backgroundPosition: 'center',
        minHeight: '100%',
        padding: 0,
        margin: 0,
      }}
    >
    <Container maxWidth="sm" sx={{ padding: '20px' }}>
      
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
        RSVP
      </Typography>
      <Typography>
        Please enter your full name to RSVP. If you are part of a group, you will be able to RSVP for the whole group to save time!
      </Typography>
      <TextField
        fullWidth
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter your name"
        margin="normal"
        variant="outlined"
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={handleSearch}
        sx={{ margin: '20px 0' }}
      >
        Search
      </Button>
      {guest && (
        <Box>
          <Typography variant="h6" component="p" sx={{ marginBottom: '10px' }}>
            Party Members:
          </Typography>
          <List>
            {partyGuests.map(guest => (
              <ListItem key={guest.id} sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ListItemText primary={guest.data().name} />
                <FormControl component="fieldset" sx={{ width: '100%' }}>
                  <FormLabel component="legend">RSVP</FormLabel>
                  <RadioGroup
                    row
                    value={responses[guest.id]}
                    onChange={(e) => handleResponseChange(guest.id, e.target.value)}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Attending" />
                    <FormControlLabel value="no" control={<Radio />} label="Not Attending" />
                  </RadioGroup>
                  <FormLabel component="legend">Transportation</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={transportation[guest.id]}
                          onChange={(e) => handleTransportationChange(guest.id, e.target.checked)}
                        />
                      }
                      label="Need transportation help"
                    />
                  </FormGroup>
                  <FormLabel component="legend">Dietary Requirements</FormLabel>
                  <FormGroup>
                    {['Gluten Free', 'Vegan', 'Kosher', 'Other'].map(option => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dietary[guest.id]?.includes(option)}
                            onChange={() => handleDietaryChange(guest.id, option)}
                          />
                        }
                        label={option}
                        key={option}
                      />
                    ))}
                  </FormGroup>
                  {dietary[guest.id]?.includes('Other') && (
                    <TextField
                      fullWidth
                      type="text"
                      value={otherDietary[guest.id] || ''}
                      onChange={(e) => handleOtherDietaryChange(guest.id, e.target.value)}
                      placeholder="Please specify"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                </FormControl>
              </ListItem>
            ))}
          </List>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRSVP}
            sx={{ marginTop: '20px' }}
          >
            Submit RSVP
          </Button>
        </Box>
      )}
      
    </Container>
    </Box>
  );
};

export default RSVP;
